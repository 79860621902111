@import '@insidedesk/tuxedo/dist/styles';

$modal-spacer: 20px;

#credential-edit-modal {
  .MuiDialog-paper {
    padding: $modal-spacer;
    min-width: 680px;
  }
}

#credential-edit-modal-title {
  margin-bottom: $modal-spacer;
}

#credential-info {
  th {
    font-weight: bold;
  }
}

#goldfinger-input-form {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  form {
    width: 100%;
  }
  .MuiDialogContent-root {
    margin-bottom: $modal-spacer;
  }
  .MuiAlert-root {
    width: 90%;
  }
  .MuiTextField-root {
    width: 80%;
  }
}

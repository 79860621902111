@import '@insidedesk/tuxedo/dist/styles';

#add-edit-input-fields {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(217, 217, 217, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: $border-light;
  border-radius: $border-radius;
  .MuiFormControl-root {
    width: 90%;
  }
  .MuiTextField-root {
    width: 80%;
  }
}

@import '~@insidedesk/tuxedo/dist/styles';

.credential-list-parent {
  .credential-tab.Mui-selected {
    border-radius: 20px 20px 0px 0px !important;
    background: $dark-purple !important;
    color: white !important;
    outline-color: $dark-purple !important;
  }

  .MuiPagination-ul {
    float: right;
  }

  .cursor-pointer {
    cursor: pointer;
  }
}
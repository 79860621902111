@import '@insidedesk/tuxedo/dist/styles';

.tab-border-end {
  background-image: linear-gradient(
    to left,
    #dee2e6 1px,
    rgba(255, 255, 255, 0) 1px
  );
  background-position: center;
  background-size: 100% $navbar-height - 20px;
  background-repeat: no-repeat;
}
